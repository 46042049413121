<template>
  <footer class="footer">
    <div class="container-lg">
      <div class="colophon">
        <span>Copyright &copy; {{currentYear}}. <a href="https://openinstitute.africa" target="_blank" rel="nofollow noreferrer">The Open Institute Trust</a>.</span>
        <span>This work is licensed under a Creative Commons Attribution-ShareAlike 4.0 International License</span>
      </div>
    </div>
    <go-top 
      :boundary=500
      :bg-color="'#1DA1F2'"
      :bottom=30
      :size=50
      :right=30
      :weight="'normal'"
    >
    </go-top>
  </footer><!-- End footer -->
</template>

<script>
import GoTop from '@inotom/vue-go-top';
export default {
  name: "Footer",
  components: { GoTop },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  }
}
</script>

<style scoped>

</style>