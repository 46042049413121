<template>

    <header class="header">
        <b-navbar toggleable="lg" class="navbar-top">

            <div class="container-lg">

                <b-navbar-brand href="/dashboard">
                    <picture>
                        <source srcset="@/assets/img/sabasi-logo-inverted.png" media=(min-width:992px)/>
                        <source srcset="@/assets/img/sabasi-logo-final.png" media=(max-width:991.98px)/>
                        <img src="@/assets/img/sabasi-logo-inverted.png" alt="Sabasi Logo"/>
                    </picture>
                </b-navbar-brand>
                <b-navbar-toggle target="admin-nav">
                    <template #default="{ expanded }">
                        <font-awesome-icon v-if="expanded" icon="times"/>
                        <font-awesome-icon v-else icon="bars"/>
                    </template>
                </b-navbar-toggle>

                <b-collapse id="admin-nav" is-nav>

                    <div class="mobile-menu-icons">
                        <ul>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/searchresults">
                                    <font-awesome-icon icon="search"/>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/notifications">
                                    <span>
                                        <font-awesome-icon icon="bell"/>
                                        <span class="badge bg-danger ms-2"
                                              :title="`You have ${notifications_count} new notifications`">{{ notifications_count }}</span>
                                    </span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/profile">
                                    <font-awesome-icon icon="user"/>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#" @click.prevent="logout()">
                                    <font-awesome-icon icon="sign-out-alt"/>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <b-navbar-nav class="nav navbar-nav me-auto align-items-center">
                        <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link">Dashboard</router-link>
                        </li>
                        <li v-if="auth.id != 390" class="nav-item">
                            <router-link to="/surveys" class="nav-link">Explore</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/profile/surveys" class="nav-link">My Posts</router-link>
                        </li>
                    </b-navbar-nav>

                    <b-navbar-nav class="nav navbar-nav ms-auto align-items-center">
                        <li class="nav-item d-none d-sm-block">
                            <router-link class="nav-link" to="/searchresults">
                                <font-awesome-icon icon="search" size="lg"/>
                                <span class="d-sm-none ms-2">Search</span>
                            </router-link>
                        </li>
                        <li class="nav-item d-none d-sm-block">
                            <router-link class="nav-link" to="/notifications">
                                <font-awesome-icon icon="bell" size="lg"/>
                                <span class="d-sm-none ms-2">Notifications</span>
                                <span class="badge bg-danger" :title="`You have ${notifications_count} unread notifications`">
                                    {{ notifications_count }}
                                </span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link">
                                <b-button class="custom-btn custom-btn-nav" v-b-modal.create-post-modal>
                                    New<span>&#43;</span>
                                </b-button>
                            </a>
                        </li>
                        <b-dropdown class="d-none d-lg-inline" variant="link" dropright no-caret>
                            <template #button-content>
                                <b-avatar variant="primary" size="35" :text="getFirstLetters(auth.name)"></b-avatar>
                            </template>
                            <b-dropdown-item>
                                <router-link to="/profile">Profile</router-link>
                            </b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="logout()">Logout</b-dropdown-item>
                        </b-dropdown>
                    </b-navbar-nav>

                </b-collapse>

            </div>

        </b-navbar>
    </header><!-- End header -->

</template>

<script>

import {mapState} from 'vuex'
export default {
    name: "Navbar",
    data() {
        return {
            showDropDown: false,
        }
    },
    mounted() {
        this.$store.dispatch('loadNotificationsCount')
    },
    methods: {
        getFirstLetters(str) {
            const firstLetters = str
                .split(' ')
                .map(word => word[0])
                .join('');

            return firstLetters;
        },
        showMenu() {
            this.showDropDown = true
        },
        logout() {
            if (confirm('Are you sure you want to logout')) {
                localStorage.clear();
                window.location.href = '/'
            }
        }
    },
    computed: {
        ...mapState(["notifications_count"]),
        auth() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + '_user'))
        },
    },
}
</script>

<style scoped>
</style>