<template>
    <footer class="footer-landing" id="footer">
        <div class="container-lg">
            <div class="footer-landing-inner">
                <a href="https://openinstitute.africa" target="_blank" rel="nofollow noreferrer" title="Visit the Open Institute website">
                  <img class="footer-logo" src="@/assets/img/oi-logo.png" alt="Open Institute logo" />
                </a>
                <p class="footer-contact-large">For help &amp; support, email us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a></p>
                <ul class="footer-links">
                    <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                    <li><a href="https://support.sabasi.mobi" rel="nofollow noreferrer" target="_blank">Release Notes</a></li>
                    <li><a href="https://support.sabasi.mobi" rel="nofollow noreferrer" target="_blank">Support</a></li>
                </ul>
                <ul class="footer-social">
                    <li><a href="mailto:help@sabasi.mobi" title="Send us an email"><font-awesome-icon :icon="['fas', 'envelope']" /></a></li>
                    <li><a href="http://twitter.com/open_institute" title="Follow us on Twitter" target="_blank" rel="nofollow noreferrer"><font-awesome-icon :icon="['fab', 'twitter']" /></a></li>
                    <li><a href="https://www.linkedin.com/company/2865525" title="Connect with us on LinkedIn" target="_blank" rel="nofollow noreferrer"><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a></li>
                    <li><a href="http://facebook.com/TheOpenInstitute" title="Follow us on Facebook" target="_blank" rel="nofollow noreferrer"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a></li>
                    <li><a href="https://www.youtube.com/channel/UC2xe-UTLYNS5AqTuFtat-Bg" title="Subscribe to our YouTube channel" target="_blank" rel="nofollow noreferrer"><font-awesome-icon :icon="['fab', 'youtube']" /></a></li>
                </ul>
                <p class="colophon">Copyright &copy; {{currentYear}}. Open Institute. All rights reserved.</p>
            </div>
        </div>
    </footer><!-- End footer -->          
</template>

<script>
export default {
  name: "FooterLanding",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  }
}
</script>