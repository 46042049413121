<template>
    <div id="app">

        <!-- Show user navbar if user is logged in -->
        <template v-if="auth">
            <Navbar/>
        </template>

        <!-- Show landing page navbar if user not logged in -->
        <template
            v-if="!auth && $router.currentRoute.path !== '/login' && $router.currentRoute.path !== '/register' && $router.currentRoute.path !== '/forgot-password'">
            <NavbarLanding/>
        </template>

        <div :class="auth ? 'wrapper' : 'wrapper wrapper-landing'">
            <router-view :auth="auth"></router-view>
        </div>

        <!-- Only show create modal and footer if user is logged in -->
        <template v-if="auth">
            <Create/>
            <Footer/>
        </template>

        <!-- Show landing page footer if user is not logged in -->
        <template
            v-if="!auth && $router.currentRoute.path !== '/login' && $router.currentRoute.path !== '/register' && $router.currentRoute.path !== '/forgot-password'">
            <FooterLanding/>
        </template>

    </div>
</template>

<script>
import Navbar from "./components/Layout/Nav/Navbar"
import NavbarLanding from "./components/Layout/Nav/NavbarLanding"
import Footer from "./components/Layout/Nav/Footer"
import FooterLanding from "./components/Layout/Nav/FooterLanding"
import Create from "./components/Views/Posts/Create"


export default {
    name: 'App',
    components: {Navbar, NavbarLanding, Create, Footer, FooterLanding},
    mounted() {
        if (this.auth) {
            window.Echo.private('user.' + this.auth.id)
                .listen('.user.notify', () => {
                    this.$store.dispatch('updateNotificationsCount')
                    this.$toaster.success("You have new notification", {timeout: 6000})
                });
        }

    },
    computed: {
        auth() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + '_user'))
        },
        excludedPaths() {
            return this.$router.currentRoute.path !== '/login' && this.$router.currentRoute.path !== '/register'
        }
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push('/login')
        }
    }
}
</script>

<style>
</style>
