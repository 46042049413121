import Vuex from 'vuex';
import Vue from 'vue'
import axios from 'axios';


// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    state: {
        // count
        notifications: [],
        notifications_count: 0,
    },


//Actions- api calls
    actions: {
        loadNotifications({commit, page}) {
            console.log(page)
            axios
                .get('api/notifications')
                .then(response => {
                    commit('SET_NOTIFICATIONS', response.data.data)
                })
        },
        loadNotificationsCount({commit}) {
            axios
                .get('api/notifications/count')
                .then(response => {
                    commit('SET_NOTIFICATIONS_COUNT', response.data.data)
                })
        },
        updateNotificationsCount: function ({commit, state}) {
            let count = state.notifications_count + 1
            commit('SET_NOTIFICATIONS_COUNT', count)
        }
    },


//Mutations
    mutations: {
        SET_NOTIFICATIONS(state, data) {
            state.notifications = data
        },
        SET_NOTIFICATIONS_COUNT(state, count) {
            state.notifications_count = count
        }
    }
})

