<template>
    <header class="header-landing">

        <b-navbar toggleable="lg" class="navbar-top bg-white">

            <div class="container-lg">

                <b-navbar-brand href="/">
                    <picture>
                        <source srcset="@/assets/img/sabasi-logo-final.png" />
                        <img src="@/assets/img/sabasi-logo-final.png" alt="Sabasi Logo" />
                    </picture>
                </b-navbar-brand>
                <b-navbar-toggle target="home-nav">
                    <template #default="{ expanded }">
                        <font-awesome-icon v-if="expanded" icon="times" />
                        <font-awesome-icon v-else icon="bars" />
                    </template>          
                </b-navbar-toggle>

                <b-collapse id="home-nav" is-nav>

                    <b-navbar-nav class="nav navbar-nav ms-auto align-items-center">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/about">About</router-link>
                        </li>                            
                        <li class="nav-item has-divider">
                            <router-link class="nav-link" to="/features">Features</router-link>
                        </li>                          
                        <li class="nav-item">
                            <router-link class="nav-link pe-0" to="/login">
                                <button class="hero-btn hero-btn-sm hero-btn-bordered">Login</button>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/register" class="nav-link">
                                <button class="hero-btn hero-btn-sm hero-btn-primary">Sign Up</button>
                            </router-link>
                        </li>                   
                    </b-navbar-nav>

                </b-collapse>
            </div>
        </b-navbar>
    </header><!-- End header -->    
</template>

<script>
export default {
  name: "NavbarLanding",
}
</script>