<template>
    <b-modal class="modal" id="create-post-modal" hide-footer centered>

        <!-- Modal title -->
        <template #modal-title>Create New Post</template>

        <div>
            <ul class="post-type-list">
                <!-- Create survey link -->
                <li class="post-type-list-item">
                    <a class="text-decoration-none" style="cursor:pointer;" @click="createSurvey()">
                        <div class="post-type-list-item__img">
                            <img src="@/assets/img/clipboard.png" alt="Survey"/>
                        </div>
                        <div class="post-type-list-item_text">
                            <h5>Survey</h5>
                            <p>Create a form with a predefined list of questions for people to answer.</p>
                        </div>
                    </a>
                </li>

                <!-- Create discussion link -->
                <li class="post-type-list-item">
                    <a class="text-decoration-none" style="cursor:not-allowed;">
                        <div class="post-type-list-item__img">
                            <img src="@/assets/img/chat.png" alt="Discussion"/>
                        </div>
                        <div class="post-type-list-item_text text-muted">
                            <h5>Discussion (Coming soon)</h5>
                            <p>Create a post or poll where ideas and opinions can be exchanged.</p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

    </b-modal><!-- End modal -->

</template>

<script>
export default {
    name: "Create",

    methods: {
        createSurvey() {
            // this.$router.push('/profile/surveys/create')
            window.location.href = ('/profile/surveys/create')
            this.$parent.isModalVisible = false
        }
    }
}
</script>
<style scoped>
</style>