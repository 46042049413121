import Vue from 'vue'
import App from './App.vue'

// Pagination
Vue.component('pagination', require('laravel-vue-pagination'));

//vuelidate
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)


//ApexCharts
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

//Bootstrap
import {BootstrapVue} from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'

Vue.use(BootstrapVue)

//CSS
import './assets/style/app.css'
import './assets/style/print.css'

//Fonts - fontawesome
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
import '@fortawesome/fontawesome-free/css/all.css'
//import '@fortawesome/fontawesome-free/js/all.js'

library.add(fas, fab)

// Axios defaults
const axios = require('axios').default;
Vue.prototype.$http = axios
Vue.config.productionTip = false

//Date Format
import moment from 'moment'

Vue.prototype.moment = moment

// Notifications
import Toaster from 'v-toaster'

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-toaster/dist/v-toaster.css'
// optional set default timeout, the default is 10000 (10 seconds).
Vue.use(Toaster, {timeout: 1000})

//Routes
import router from './routes/routes'

// vuex - state management
import store from './store/store';

//vue-shairing
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

// Pusher
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Pusher.logToConsole = false;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authEndpoint: process.env.VUE_APP_URL + `/broadcasting/auth`,
    forceTLS: true,
    auth: {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem(process.env.VUE_APP_NAME + '_token')
        },
    },
});

//pdfPRINT
import VueHtmlToPaper from 'vue-html-to-paper';

let basePath = window.location.origin

const options = {
    "name": '_blank',
    "specs": ['fullscreen=no', 'titlebar=no', 'scrollbars=yes'],
    "styles": [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        `${basePath}/print.css`
    ],
    "windowTitle": 'Sabasi - Survey Respondents',
    "autoClose": false
};

Vue.use(VueHtmlToPaper, options);

// Global variables
let token_key = process.env.VUE_APP_NAME + '_token'
let user_key = process.env.VUE_APP_NAME + '_user'

// Axios defaults
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.baseURL = process.env.VUE_APP_URL
axios.defaults.withCredentials = false

// Set authorization header
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem(token_key)
    if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Auth middleware
router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.auth == true)) {
        if (localStorage.getItem(token_key) == null) {
            window.location.href = '/login'
        }
    }
    next();
});

// Always redirect to login if not authenticated
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response) {
        if (403 === error.response.status) {
            localStorage.removeItem(token_key)
            localStorage.removeItem(user_key)
            window.location.href = '/login';
        }
    }
    return Promise.reject(error);
});

// Title middleware
router.afterEach((to) => {
    document.title = 'Sabasi Client' + ' - ' + to.meta.title || ''
});

//vue-mixin
Vue.mixin({
    data: function () {
        return {
            wait: false,
            error: '',
            success: ''
        }
    },
    methods: {
        clear() {
            this.error = this.success = ''
        },
        setLoading() {
            this.wait = true
        },
        showError(error) {
            this.clear()
            if (error.response) {
                this.error = error.response.data.data
            } else if (error.request) {
                // this.error = "Error connecting to the server"
                this.error = error.request
            } else {
                this.error = error.message
            }
        }
    }
})

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
